import React from 'react';

const ampStyles = {
  icon: {
    fill: 'white'
  }
};

const IconCopy = () => {
  return (
    <svg
      aria-label="Copy"
      style={ampStyles.icon}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_861_6746)">
        <path
          d="M4.1665 12.5C3.38993 12.5 3.00165 12.5 2.69536 12.3732C2.28698 12.204 1.96253 11.8795 1.79337 11.4712C1.6665 11.1649 1.6665 10.7766 1.6665 10V4.33335C1.6665 3.39993 1.6665 2.93322 1.84816 2.5767C2.00795 2.2631 2.26292 2.00813 2.57652 1.84834C2.93304 1.66669 3.39975 1.66669 4.33317 1.66669H9.99984C10.7764 1.66669 11.1647 1.66669 11.471 1.79355C11.8794 1.96271 12.2038 2.28717 12.373 2.69555C12.4998 3.00183 12.4998 3.39012 12.4998 4.16669M10.1665 18.3334H15.6665C16.5999 18.3334 17.0666 18.3334 17.4232 18.1517C17.7368 17.9919 17.9917 17.7369 18.1515 17.4233C18.3332 17.0668 18.3332 16.6001 18.3332 15.6667V10.1667C18.3332 9.23327 18.3332 8.76656 18.1515 8.41004C17.9917 8.09643 17.7368 7.84147 17.4232 7.68168C17.0666 7.50002 16.5999 7.50002 15.6665 7.50002H10.1665C9.23308 7.50002 8.76637 7.50002 8.40985 7.68168C8.09625 7.84147 7.84128 8.09643 7.68149 8.41004C7.49984 8.76656 7.49984 9.23327 7.49984 10.1667V15.6667C7.49984 16.6001 7.49984 17.0668 7.68149 17.4233C7.84128 17.7369 8.09625 17.9919 8.40985 18.1517C8.76637 18.3334 9.23308 18.3334 10.1665 18.3334Z"
          stroke="#344054"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_861_6746">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconCopy;
