import React, { FC } from 'react';

interface Props {
  elementClass?: string;
}

const IconPrevious: FC<Props> = (props) => (
  <svg
    className={`icon icon-previous ${
      props.elementClass ? props.elementClass : ''
    }`}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M6 6h2v12H6zm3.5 6l8.5 6V6z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

export default IconPrevious;
