import React from 'react';

const IconMail = () => {
  return (
    <svg
      aria-label="Email"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_631_106101)">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#7ECC52"
        />
        <path
          d="M17.5415 15.5L13.6665 12M10.3332 12L6.45819 15.5M6.1665 9.08333L10.9294 12.4173C11.3151 12.6873 11.5079 12.8223 11.7177 12.8746C11.9029 12.9208 12.0967 12.9208 12.282 12.8746C12.4918 12.8223 12.6846 12.6873 13.0703 12.4173L17.8332 9.08333M8.9665 16.6667H15.0332C16.0133 16.6667 16.5033 16.6667 16.8777 16.4759C17.2069 16.3081 17.4747 16.0404 17.6424 15.7111C17.8332 15.3368 17.8332 14.8468 17.8332 13.8667V10.1333C17.8332 9.15324 17.8332 8.66319 17.6424 8.28884C17.4747 7.95956 17.2069 7.69185 16.8777 7.52407C16.5033 7.33333 16.0133 7.33333 15.0332 7.33333H8.9665C7.98641 7.33333 7.49637 7.33333 7.12202 7.52407C6.79274 7.69185 6.52502 7.95956 6.35724 8.28884C6.1665 8.66319 6.1665 9.15324 6.1665 10.1333V13.8667C6.1665 14.8468 6.1665 15.3368 6.35724 15.7111C6.52502 16.0404 6.79274 16.3081 7.12202 16.4759C7.49637 16.6667 7.98641 16.6667 8.9665 16.6667Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_631_106101">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconMail;
