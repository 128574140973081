import { FC } from 'react';

interface Props {
  height?: string;
  width?: string;
}

const IconDelete: FC<Props> = ({ height = '16', width = '14' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3333 5.00008L7.33333 9.00008M7.33333 5.00008L11.3333 9.00008M1.81333 7.64008L4.69333 11.4801C4.928 11.793 5.04533 11.9494 5.19403 12.0622C5.32574 12.1622 5.47488 12.2368 5.63385 12.2822C5.81334 12.3334 6.00889 12.3334 6.4 12.3334H11.4667C12.5868 12.3334 13.1468 12.3334 13.5746 12.1154C13.951 11.9237 14.2569 11.6177 14.4487 11.2414C14.6667 10.8136 14.6667 10.2535 14.6667 9.13341V4.86675C14.6667 3.74664 14.6667 3.18659 14.4487 2.75877C14.2569 2.38244 13.951 2.07648 13.5746 1.88473C13.1468 1.66675 12.5868 1.66675 11.4667 1.66675H6.4C6.00889 1.66675 5.81334 1.66675 5.63385 1.71801C5.47488 1.76341 5.32574 1.83798 5.19403 1.93792C5.04533 2.05075 4.928 2.20719 4.69333 2.52008L1.81333 6.36008C1.6412 6.58959 1.55514 6.70434 1.52196 6.83037C1.49268 6.94162 1.49268 7.05855 1.52196 7.16979C1.55514 7.29582 1.6412 7.41058 1.81333 7.64008Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconDelete;
