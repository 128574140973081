import React, { ComponentType, FC } from 'react';
export { default as IconPlay } from './IconPlay';
export { default as IconDots } from './IconDots';
export { default as IconDelete } from './IconDelete';
export { default as IconShare } from './IconShare';
export { default as IconCloseModal } from './IconCloseModal';
export { default as IconCopy } from './IconCopy';
export { default as IconReddit } from './IconReddit';
export { default as IconMail } from './IconMail';
export { default as IconPinterest } from './IconPinterest';
export { default as IconFacebook } from './IconFacebook';

import IconForward from './IconForward';
import IconMute from './IconMute';
import IconNext from './IconNext';
import IconPause from './IconPause';
import IconPlay from './IconPlay';
import IconPrevious from './IconPrevious';
import IconReplay from './IconReplay';
import IconVolume from './IconVolume';

export type IconType =
  | 'forward'
  | 'mute'
  | 'next'
  | 'pause'
  | 'play'
  | 'previous'
  | 'replay'
  | 'volume';

const icons: Record<IconType, ComponentType> = {
  forward: IconForward,
  mute: IconMute,
  next: IconNext,
  pause: IconPause,
  play: IconPlay,
  previous: IconPrevious,
  replay: IconReplay,
  volume: IconVolume
};

interface Props {
  name: IconType;
  elementClass?: string;
}

const Icon: FC<Props> = (props) => {
  const { name, ...rest } = props;

  const Element = icons[name];

  return <Element key="" {...rest} />;
};

export default Icon;
