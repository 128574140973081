import { FC } from 'react';

interface Props {
  height?: string;
  width?: string;
  active?: boolean;
}

const IconDots: FC<Props> = ({ height = '16', width = '16', active }) => {
  const color = active ? 'var(--color-white)' : 'var(--color-current-red)';
  return (
    <svg
      width={width}
      height={height}
      color={color}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="ellipse"
        d="M2.00008 8.83325C2.46032 8.83325 2.83341 8.46016 2.83341 7.99992C2.83341 7.53968 2.46032 7.16659 2.00008 7.16659C1.53984 7.16659 1.16675 7.53968 1.16675 7.99992C1.16675 8.46016 1.53984 8.83325 2.00008 8.83325Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="ellipse"
        d="M2.00008 2.99992C2.46032 2.99992 2.83341 2.62682 2.83341 2.16659C2.83341 1.70635 2.46032 1.33325 2.00008 1.33325C1.53984 1.33325 1.16675 1.70635 1.16675 2.16659C1.16675 2.62682 1.53984 2.99992 2.00008 2.99992Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="ellipse"
        d="M2.00008 14.6666C2.46032 14.6666 2.83341 14.2935 2.83341 13.8333C2.83341 13.373 2.46032 12.9999 2.00008 12.9999C1.53984 12.9999 1.16675 13.373 1.16675 13.8333C1.16675 14.2935 1.53984 14.6666 2.00008 14.6666Z"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconDots;
